import { GetStaticProps, NextPage } from 'next';
import { initializeApollo } from '../utils/apollo';
import {
  Blog,
  HomePage as HomePageType,
  HomePage as HomePageGraphql,
  HomePageHomeDynamicDynamicZone,
  Technology,
  Testimony,
} from '../types/schema';
import HomePage from '../components/elements/HomePage';
import { HOME_PAGE } from '../queries/home';
import { flatten, translateProperty } from '../utils/object';
import { formatAvailableJobs } from '../utils/getAvailableJobsDevjs';
import { AvailableJobItemProps } from '../components/ui/AvailableJobItem';
import PageLayout, { MenuProps } from '../layouts/Page';
import { Error, sendError } from '../utils/error';

type HomeProps = {
  data: HomePageGraphql;
  technologies: Technology[];
  testimonies: Testimony[];
  locale: string;
  blogs: Blog[];
  jobs: AvailableJobItemProps[];
  menus: MenuProps;
};

/**
 * Home
 */
const Home: NextPage<HomeProps> = ({
  data,
  technologies,
  locale,
  testimonies,
  blogs,
  jobs,
  menus,
}) => {
  return (
    <PageLayout menus={menus} locale={locale} isHeaderTransparent>
      <HomePage
        metaTitle={data?.metadata?.metaTitle as string}
        hideAppMastersTitle={data?.metadata?.hideAppMastersTitle as boolean}
        metaDescription={data?.metadata?.metaDescription as string}
        metaImage={data?.metadata?.metaImage?.data?.attributes?.url as string}
        title={data.title as string}
        description={data.description as string}
        bannerUrl={data.banner?.data?.attributes?.url}
        homeDynamic={data.homeDynamic as HomePageHomeDynamicDynamicZone[]}
        locale={locale}
        allTechnologies={technologies}
        testimonies={testimonies}
        blogs={blogs}
        jobs={jobs}
      />
    </PageLayout>
  );
};

/**
 * getStaticProps
 */
export const getStaticProps: GetStaticProps = async ({ locale }) => {
  try {
    const apolloClient = initializeApollo();
    const { data } = await apolloClient.query({
      query: HOME_PAGE,
      variables: {
        locale,
      },
    });

    const newData = flatten(data);

    const translatedObj = translateProperty(
      newData.homePage as HomePageType,
      ['background', 'button', 'title', 'limit', 'backgroundColor', 'content'],
      'homeDynamic',
    );

    const formatedAvailableJobs = await formatAvailableJobs();
    return {
      props: {
        data: translatedObj as HomePageType,
        technologies: newData.technologiesData,
        testimonies: newData.testimonies,
        blogs: newData.blogs,
        locale,
        jobs: formatedAvailableJobs,
        menus: {
          header: newData.header,
          technologies: newData.technologies,
          services: newData.services,
          footerPt: newData.footerPt,
          footerEn: newData.footerEn,
        },
      },
      revalidate: 3600 * 12,
    };
  } catch (error) {
    sendError({ error: error as Error, message: 'Error on home page' });
    return {
      notFound: true,
    };
  }
};

export default Home;
