import styled from 'styled-components';
import media from '../../../styles/media';

export const HomePageWrapper = styled.div`
  min-height: 100%;
  p,
  p > span {
    color: ${(props) => props.theme.colors.darkTextPale};
    font-weight: 400 !important;
  }
`;

export const BannerContainer = styled.div<{ bgImage?: string }>`
  display: flex;
  justify-content: center;
  position: relative;
  height: 100vh;
  background: ${(props) => props.theme.colors.darkGradient};
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  &::after {
    content: ' ';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background: white;
  }
  > div {
    inset: 0;
  }

  ${media('extraLargest')} {
    /* aspect-ratio: 520/200; */
    background-size: 200%;
    background-position-x: 30%;
  }
  ${media('extraLarge', 'extraLargest')} {
    /* aspect-ratio: 520/350; */
  }

  ${media('mediumPlus', 'extraLarge')} {
    /* aspect-ratio: 520/450; */
  }
  ${media('medium', 'mediumPlus')} {
    /* aspect-ratio: 520/600; */
  }
  ${media('small', 'medium')} {
    padding-top: 60px;
    /* aspect-ratio: 520/480; */
  }
  ${media('extraSmallest', 'small')} {
    /* aspect-ratio: 520/980; */
  }
  ${media('extraSmallest', true)} {
    /* aspect-ratio: 520/1080; */
  }

  ${media('medium', true)} {
    h1 {
      font-size: 30px;
    }
    div.img {
      display: none;
    }
  }
  ${media('extraSmallest', true)} {
    h1 {
      font-size: 26px;
    }
  }
`;

export const BannerSVG = styled.div`
  position: absolute;
  inset: 0;
  overflow: hidden;
  line-height: 0;
  svg {
    height: 100%;
    width: 120%;
    transform: translateX(-10%);
  }
`;

export const BannerContent = styled.div`
  z-index: 2;
  max-width: 85%;
  margin-top: 100px;
  ${media('extraLarge')} {
    max-width: 1200px;
  }
  ${media('medium', true)} {
    max-width: 1200px;
  }
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 26px;
  ${media('extraLarge')} {
    padding: 0 26px 0 0;
  }
  h1 {
    line-height: 1.1;
    color: red;
  }
  p > span {
    font-size: 18px;
    line-height: 1.8;
    font-weight: 400;
    color: #f2f5ff;
  }

  > div:first-child {
    flex: 1 1 0%;
  }

  img,
  svg {
    display: none;
    width: 45%;
    object-fit: contain;
    ${media('medium')} {
      display: block;
    }
  }
`;

export const HomeContent = styled.div<{
  background?: 'transparent' | 'white' | 'primary' | null;
}>`
  ${(props) =>
    props.background === 'primary'
      ? `
    background-color: ${props.theme.colors.blue};
    color: white !important;
    p {
      color: white !important;
    }
  `
      : `
        background-color: ${props.background === 'white' ? 'white' : 'transparent'};
      `}
  padding: 86px 16px;
`;
