import { HomePageWrapper, BannerContainer, BannerContent } from './styles';
import { Blog, HomePageHomeDynamicDynamicZone, Technology, Testimony } from '../../../types/schema';

import DynamicZones from '../DynamicZones';
import Typography from '../../ui/Typography';
import { AvailableJobItemProps } from '../../ui/AvailableJobItem';
import Image from 'next/image';

export type HomePageTemplateProps = {
  metaTitle?: string;
  hideAppMastersTitle?: boolean;
  metaDescription?: string;
  metaImage?: string;
  title: string;
  description: string;
  bannerUrl?: string;
  homeDynamic: HomePageHomeDynamicDynamicZone[];
  locale: string;
  allTechnologies?: Technology[];
  testimonies?: Testimony[];
  blogs?: Blog[];
  jobs: AvailableJobItemProps[];
};

/**
 * HomePageTemplate
 */
const HomePageTemplate: React.FC<HomePageTemplateProps> = ({
  title,
  description,
  bannerUrl,
  homeDynamic,
  locale,
  allTechnologies,
  testimonies,
  blogs,
  jobs,
}) => {
  return (
    <HomePageWrapper>
      <BannerContainer>
        <BannerContent>
          <div>
            <Typography variant="h1" color="white" fontWeight="bold">
              {title}
            </Typography>
            <Typography variant="p" fontSize="20" color="white" fontWeight="500">
              {description}
            </Typography>
          </div>
          <Image
            src={bannerUrl || ''}
            alt="Banner da App Masters"
            loading="lazy"
            width={470}
            height={470}
            quality={100}
            sizes="100vw"
            style={{
              minWidth: '45%',
            }}
          />
        </BannerContent>
      </BannerContainer>
      <DynamicZones
        dynamicZones={homeDynamic}
        language={locale}
        allTechnologies={allTechnologies}
        allTestimonies={testimonies}
        lastBlogs={blogs}
        techsInsideContainer={false}
        jobs={jobs}
      />
    </HomePageWrapper>
  );
};

export default HomePageTemplate;
