import HomePageTemplate, { HomePageTemplateProps } from './template';
import { useRouter } from 'next/router';
import {
  OrganizationJsonLd,
  NextSeo,
  SocialProfileJsonLd,
  OrganizationJsonLdProps,
  SocialProfileJsonLdProps,
} from 'next-seo';
import { additionalLinkTags, SEO } from '../../../utils/SEO';

/**
 * HomePage
 */
const HomePage: React.FC<HomePageTemplateProps> = (props) => {
  const siteUrl = process.env.NEXT_PUBLIC_SITE_URL || 'http://localhost:3000';
  const router = useRouter();
  const isEn = router.locale === 'en';

  const profile: SocialProfileJsonLdProps = {
    type: 'Organization',
    name: 'App Masters',
    url: 'https://appmasters.io',
    sameAs: [
      'https://www.facebook.com/appmasters.io/',
      'https://www.instagram.com/appmasters.io/',
      'https://www.linkedin.com/company/appmasters.io/',
      'https://appmasters.io/en/',
      'https://appmasters.io/',
    ],
  };

  const organization: OrganizationJsonLdProps = {
    type: 'Corporation',
    id: 'http://appmasters.io',
    name: 'App Masters',
    legalName: 'Mestres em aplicativos LTDA',
    url: siteUrl,
    sameAs: profile.sameAs,
    address: {
      streetAddress: isEn ? '3480 Barão do Rio Branco Avenue' : 'Avenida Barão do Rio Branco, 3480',
      addressLocality: 'Juiz de Fora',
      addressRegion: 'MG',
      postalCode: '36025-020',
      addressCountry: 'BR',
    },
    logo: 'https://res.cloudinary.com/appmasters-io/image/upload/v1629152598/publisher_white_logo_70c2c766f5.png',
  };

  return (
    <>
      <NextSeo
        {...SEO({
          pathname: '',
          locale: router.locale as string,
          title: (props.metaTitle || props.title) as string,
          description: props.metaDescription as string,
          image: props.metaImage as string,
          hideAppMastersTitle: props.hideAppMastersTitle as boolean,
        })}
        additionalLinkTags={additionalLinkTags(['', ''])}
      />
      <SocialProfileJsonLd {...profile} />
      <OrganizationJsonLd {...organization} />
      <HomePageTemplate {...props} />
    </>
  );
};

export default HomePage;
